import React from 'react';
import { Typography } from '../../constants/typography';
import PrimaryButton from '../buttons/primary';
import { Constants } from '../../constants';
import { sendTagEvent } from '../../constants/google-tags';

const HomeGetTheGreen = ({ content }) => (
  <div
    id="get-the-green"
    className={`
      ${Constants.panelBase}
      bg-yellow
      dark-header header-section
      flex flex-col items-center justify-center
      px-8 lg:p-16
    `}
  >
    <h1
      className={`${Typography.h1} ${Constants.fadeUp} text-charcoal text-center`}
      data-scroll
      data-scroll-class="fade-up-visible"
      data-scroll-offset="25%"
      data-scroll-repeat="true"
    >
      <span className="block">
        {content.fillText}
      </span>
      <span className={`block sm:mt-3 ${Typography.strokedCharcoal}`}>
        {content.outlineText}
      </span>
    </h1>
    <p
      className={`${Typography.body1} ${Constants.fadeUp} text-center max-w-[630px] mt-10 mb-10 lg:mt-6 lg:mb-12`}
      data-scroll
      data-scroll-class="fade-up-visible"
      data-scroll-offset="25%"
      data-scroll-repeat="true"
    >
      {content.bodyText}
    </p>
    <div
      className={`${Constants.fadeUp} flex flex-col lg:flex-row items-center lg:items-start lg:justify-center space-y-5 lg:space-y-0 lg:space-x-5`}
      data-scroll
      data-scroll-class="fade-up-visible"
      data-scroll-offset="25%"
      data-scroll-repeat="true"
    >
      <PrimaryButton
        to={content.learnMoreButton.link}
        hoverClassName="hover:border-white hover:bg-white hover:text-charcoal"
        onClick={() => sendTagEvent('click', {
          event_category: 'Homepage',
          event_label: 'Learn more',
        })}
      >
        {content.learnMoreButton.label}
      </PrimaryButton>
      <PrimaryButton
        to={content.partnerButton.link}
        variant="outlined"
        hoverClassName="hover:border-white hover:bg-white hover:text-charcoal"
        onClick={() => sendTagEvent('click', {
          event_category: 'Homepage',
          event_label: 'Partner with us',
        })}
      >
        {content.partnerButton.label}
      </PrimaryButton>
    </div>
  </div>
);

export default HomeGetTheGreen;
