/* eslint-disable no-console */
import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/app-layout';
import FastTrack from '../components/home/fast-track';
import HomeHero from '../components/home/hero';
import Press from '../components/home/press';
import FeaturedCreators from '../components/home/featured-creators';

const IndexPage = ({ data }) => {
  const { home } = data.home;
  const {
    heroHome, componentA, componentB, componentC, componentD,
  } = home;
  return (
    <Layout defaultHeaderColor="text-white">
      <SEO title={data.home.title} />
      <div className="w-full h-full bg-charcoal" data-scroll-section>
        <HomeHero
          content={heroHome}
          componentA={componentA}
        />
        <div className="bg-charcoal overflow-hidden">
          <FastTrack content={componentB} />
          <FeaturedCreators content={componentC} />
        </div>
        <Press content={componentD} />
      </div>
    </Layout>
  );
};

export default IndexPage;

export const homeData = graphql`
  {
    home: wpPage(databaseId: {eq: 15}) {
      title
      home {
        heroHome {
          titles {
            titleOutline
            titleFill
          }
          videoCollage {
            video {
              localFile {
                id
                publicURL
              }
            }
          }
        }
        componentA {
          titleFill
          titleOutline
          paragraph
          buttonA {
            label
            link
          }
          buttonB {
            label
            link
          }
        }
        componentB {
          title
          facts {
            number {
              prependText
              number
              appendText
            }
            fact
          }
        }
        componentC {
          title
          message
          creators {
            name
            type
            subtitle
            youtubeUrl
            videoDuration
            thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        componentD {
          title
          buttonTitle
          articles {
            quote
            articleUrl
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;
