import React, { useEffect, useRef, useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Typography } from '../../constants/typography';
import Grid from '../grid';
import IconAsteriskOutline from '../icons/asterisk-outline';
import Button from '../buttons/button';
import SecondaryButton from '../buttons/secondary';
import { Constants } from '../../constants';
import { sendTagEvent } from '../../constants/google-tags';

const Press = ({ content }) => {
  const { title, buttonTitle, articles } = content;
  const [currentArticle, setCurrentArticle] = useState(articles[0]);
  const [maxHeight, setMaxHeight] = useState(articles[0]);
  const articleIndex = useRef(0);
  const parentEl = useRef();
  const interval = useRef();
  useEffect(() => {
    const changeIndex = () => {
      articleIndex.current += 1;
      if (articleIndex.current >= articles.length) articleIndex.current = 0;
      setCurrentArticle(articles[articleIndex.current]);
    };
    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) interval.current = setInterval(changeIndex, 4000);
        else clearInterval(interval.current);
      });
    };
    const observer = new IntersectionObserver(callback, { rootMargin: '0px', threshold: 1.0 });
    observer.observe(parentEl.current);
    return () => {
      clearInterval(interval.current);
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    const articles = parentEl.current.getElementsByClassName('article-btn');
    const maxHeight = Math.max(...Array.from(articles).map((article) => article.offsetHeight));
    setMaxHeight(maxHeight);
  }, [currentArticle]);
  return (
    <div
      id="press"
      ref={parentEl}
      className={`
        relative
        w-full
        bg-white dark-header header-section
        text-black
        px-8 pt-20 pb-32 lg:p-16 lg:pt-40 lg:pb-32
        overflow-hidden
      `}
    >
      <div className="absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2">
        <IconAsteriskOutline className="w-[360px] lg:w-[820px]" pathClassName="text-yellow" />
      </div>
      <Grid>
        <h1 className={`
          col-span-full lg:col-span-5
          font-medium
          text-[36px]
          leading-[100%]
          text-left
          pt-4 pb-9 lg:py-0
        `}
        >
          <span className="block w-8/12 lg:max-w-[170px]">{title}</span>
        </h1>
        <div
          style={{ height: maxHeight }}
          className={`
            col-span-full lg:col-span-5
            ${Typography.body1}
            text-left
          `}
        >
          {
            articles.map((item) => {
              const isVisible = currentArticle.articleUrl === item.articleUrl;
              return (
                <Button
                  key={item.articleUrl}
                  href={item.articleUrl}
                  target="_blank"
                  className={`article-btn pr-6 lg:pr-0 lg:max-w-[490px] ease-out absolute transition-opacity ${isVisible ? 'opacity-100 delay-100 duration-1000 pointer-events-auto' : 'opacity-0 delay-0 duration-100 pointer-events-none'}`}
                >
                  <div className="relative">
                    <span className={`left-[-70px] lg:left-[-110px] top-11 absolute text-[288px] lg:text-[400px] ${Typography.strokedYellow}`}>“</span>
                    <span className="absolute -left-3">“</span>
                    <span className="relative z-10">{item.quote}</span>
                    <span>”</span>
                  </div>
                  <GatsbyImage
                    className="h-[40px] lg:h-[70px] w-auto max-w-[300px] my-8 lg:my-4"
                    objectFit="contain"
                    objectPosition="0"
                    image={getImage(item.logo.localFile)}
                    alt={item.quote}
                  />
                  <SecondaryButton
                    className="!pl-0 font-sans"
                    onClick={() => sendTagEvent('click', {
                      event_category: 'Homepage',
                      event_label: 'Read more',
                    })}
                  >
                    {buttonTitle}
                  </SecondaryButton>
                </Button>
              );
            })
          }
        </div>
      </Grid>
    </div>
  );
};

export default Press;
