/* eslint-disable react/no-danger */
import React from 'react';
import { Constants } from '../../constants';
import { Typography } from '../../constants/typography';
import Carousel from '../carousel';
import SkewRotateAsterisk from '../icons/skew-rotate-asterisk';

const Stat = ({ stat }) => (
  <div className="w-full flex flex-col lg:flex-row justify-center lg:justify-start">
    <div className={`flex flex-col lg:flex-row ${Typography.h3}`}>
      <span className={`text-[72px] tl:text-[48px] tl:flex tl:items-center leading-[85%] ${Typography.strokedWhite}`}>
        <span>{stat.number.prependText}</span>
        <span>{stat.number.number}</span>
        <span>{stat.number.appendText}</span>
      </span>
      <span className="hidden lg:inline">&nbsp;</span>
      {stat.fact}
    </div>
  </div>
);

const FastTrack = ({ content }) => {
  const { title, facts } = content;
  return (
    <div
      id="fast-track"
      className={`
        relative
        w-screen
        lg:min-h-full
        h-auto
        bg-charcoal light-header header-section
        flex flex-col items-end justify-start
        pb-10 pt-24 tl:px-16 lg:px-16 lg:pb-16 lg:pt-32
        text-white
        z-10
      `}
    >
      <div className="relative flex-1 flex flex-col h-full w-full z-10">
        <h5
          dangerouslySetInnerHTML={{ __html: title }}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="25%"
          data-scroll-repeat="true"
          className={`${Constants.fadeUp} ${Typography.h4} px-8 lg:px-0 lg:max-w-[620px] relative z-10 hidden lg:flex flex-col justify-center space-y-10`}
        />
        <div
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="25%"
          data-scroll-repeat="true"
          className={`${Constants.fadeUp} w-full flex-1 pt-18 lg:py-18`}
        >
          <Carousel className="lg:hidden" activeColor="after:bg-yellow" navPosition="justify-start px-6">
            {
              facts.map((stat) => (
                <div key={`fast-track-mobile-${stat.fact}`} className="w-full flex justify-center px-8">
                  <Stat stat={stat} />
                </div>
              ))
            }
          </Carousel>
          <div className="relative z-10 hidden lg:flex flex-col justify-center space-y-10">
            {
              facts.map((stat) => (
                <Stat key={`fast-track-desktop-${stat.fact}`} stat={stat} />
              ))
            }
          </div>
        </div>
      </div>
      <SkewRotateAsterisk pathClassName="text-yellow" />
    </div>
  );
};

export default FastTrack;
