/* eslint-disable max-len */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import YouTube from 'react-youtube';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import SwiperCore, { Navigation } from 'swiper';
import { selectIsLandscape, selectIsMobile, selectIsTablet } from '../../state/reducers/utils';
import Button from '../buttons/button';
import { Typography } from '../../constants/typography';
import IconAsterisk from '../icons/asterisk';
import PrimaryButton from '../buttons/primary';
import { Constants } from '../../constants';
import { sendTagEvent } from '../../constants/google-tags';

SwiperCore.use([Navigation]);

const FeaturedCreator = React.memo(({
  creator,
  height,
  isMobile,
  swiper,
  index,
  id,
}) => {
  const [actuallyActive, setActuallyActive] = useState(index === 0);
  const [isPlaying, setIsPlaying] = useState(false);
  const ytPlayer = useRef(null);
  const videoPaused = () => {
    setIsPlaying(false);
  };
  const onReady = (event) => {
    ytPlayer.current = event.target;
    ytPlayer.current.playVideo();
  };
  useEffect(() => {
    swiper.current.on('slideChange', () => {
      setActuallyActive(swiper.current.realIndex === index);
    });
  }, []);
  useEffect(() => {
    if (!actuallyActive) setIsPlaying(false);
  }, [actuallyActive]);
  const onClickToPlay = () => {
    if (!actuallyActive) return;
    setIsPlaying(true);
  };
  return (
    <>
      <div style={{ height }} className="sm:flex sm:flex-col sm:justify-center">
        <div
          onClick={onClickToPlay}
          className={`
            relative
            h-full w-full
            transition-all duration-500 lg:duration-1000
            sm:delay-200
            cursor-pointer
            group
          `}
          style={{ height, maxHeight: actuallyActive ? height : height * 0.75 }}
        >
          <div
            className={`
              absolute
              z-30
              w-full h-full
              bg-charcoal
              transition-all duration-1000 ease-in-out
              pointer-events-none
              ${actuallyActive ? 'opacity-0' : 'opacity-70 supportshover:group-hover:opacity-30'}
            `}
          />
          <div
            className={`
              absolute
              h-full w-full
              transition-all
              duration-1000
              z-20
              ${actuallyActive && isPlaying ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'}
            `}
          >
            <GatsbyImage
              image={getImage(creator.thumbnail.localFile)}
              alt="test"
              imgStyle={{ opacity: 1, transition: 'all 0s' }}
              loading="eager"
              className={`
                !opacity-100
                absolute
                h-full w-full
                object-cover object-center
                z-20
              `}
            />
            <span className={`
              absolute
              bottom-1 right-1
              triangle
              small
              text-white supportshover:group-hover:text-yellow
              z-30
              transform
              scale-50
              transition-all
              duration-500
              pointer-events-none
              ${actuallyActive && !isPlaying ? 'opacity-100' : 'opacity-0'}
            `}
            >
              ▲
            </span>
          </div>
          {
            (isPlaying) && (
              <YouTube
                id={id}
                ref={ytPlayer}
                className={`
                  w-full h-full absolute top-0 left-0 z-10 opacity-0
                  ${isPlaying ? 'opacity-100' : 'opacity-0'}
                `}
                opts={{
                  playerVars: {
                    playsinline: 1,
                    rel: 0,
                    showinfo: 0,
                    modestbranding: 0,
                    autoplay: false,
                  },
                }}
                onReady={onReady}
                onPause={videoPaused}
                videoId={creator.youtubeUrl.split('?v=')[1]}
              />
            )
          }
        </div>
      </div>
      <div
        className={`
          relative
          transition-all
          transform
          lg:px-8
          text-white
          z-20
          pointer-events-none
          ease-out-expo
          delay-500
          ${actuallyActive && (isMobile || !isPlaying) ? 'opacity-100 duration-700 lg:duration-1000 translate-y-4 lg:-translate-y-20' : 'duration-700 opacity-0 translate-y-10 lg:-translate-y-10'}
        `}
      >
        <span className={`${Typography.categoryButton} lg:pl-1 block -mb-1 sm:mb-1`}>
          {creator.type}
        </span>
        <h2 className={`${Typography.h2} text-[36px] lg:text-[96px]`}>
          {creator.name}
        </h2>
        <div
          className={`${Typography.body2} leading-[120%] mt-2 lg:mt-5 max-w-[330px]`}
        >
          <span dangerouslySetInnerHTML={{ __html: creator.subtitle }} />
          <span className="block mt-2 lg:mt-5 text-gray3">
            {creator.videoDuration}
            {' '}
            watch
          </span>
        </div>
      </div>
    </>
  );
}, (prevProps, nextProps) => prevProps.creator.id === nextProps.creator.id && prevProps.index === nextProps.index && prevProps.height === nextProps.height);

const FeaturedCreators = ({ content }) => {
  const scrollEl = useRef(null);
  const swiper = useRef(null);
  const nextRef = useRef();
  const prevRef = useRef();
  const isMobile = useSelector(selectIsMobile);
  const isTablet = useSelector(selectIsTablet);
  const isLandscape = useSelector(selectIsLandscape);
  const [data] = useState([...content.creators]);
  const [height, setHeight] = useState(0);

  const gtagClickIds = [
    'deestroying',
    'mrbeast',
    'zhc',
    'gloom',
    'guava',
  ];
  const [prevButtonId, setPrevButtonId] = useState(gtagClickIds.length - 1);
  const [nextButtonId, setNextButtonId] = useState(1);

  const scrollIntoFocus = () => {
    if (window.locoScroll.scroll.instance.scroll.y < scrollEl.current.offsetTop) {
      window.locoScroll.scrollTo(scrollEl.current);
    }
  };

  const goNext = (e) => {
    changeButtonId();
    scrollIntoFocus();
  };

  const goPrev = (e) => {
    changeButtonId();
    scrollIntoFocus();
  };

  const changeButtonId = () => {
    const index = swiper.current.activeIndex - 1;
    if (index === 0 || index === 5) {
      setNextButtonId(1);
      setPrevButtonId(gtagClickIds.length - 1);
    } else if (index === gtagClickIds.length - 1) {
      setNextButtonId(0);
      setPrevButtonId(index - 1);
    } else {
      setNextButtonId(index + 1);
      setPrevButtonId(index - 1);
    }
  };

  useEffect(() => {
    if (window && isMobile !== null) {
      const activeW = isMobile || (isTablet && !isLandscape)
        ? (window.innerWidth - 120)
        : (window.innerWidth - 120) * 0.75;
      const sHeight = isMobile || (isTablet && !isLandscape)
        ? Math.min((activeW * 1.5), window.innerHeight * 0.6666)
        : activeW * 0.5625;
      setHeight(sHeight);
    }
  }, [isMobile]);

  return (
    <div
      id="featured-creators"
      className={`
        relative
        h-auto w-full
        z-20
        flex flex-col
        px-[30px] pb-16
        pt-8 lg:pt-24
        text-white
        overflow-hidden
        featured-creators-bg
      `}
      ref={scrollEl}
    >
      <h4
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="25%"
        data-scroll-repeat="true"
        className={`${Constants.fadeUp} font-medium text-[34px] lg:text-[36px] leading-[100%] max-w-[640px] text-left mb-16 lg:mb-18 pr-4 lg:px-8`}
      >
        {content.title}
      </h4>
      <div className="relative w-full h-16 -mt-16" />
      <div
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="25%"
        data-scroll-repeat="true"
        className={`${Constants.fadeUp} relative w-full flex justify-end pr-0 ml-2 -mt-4 mb-2`}
      >
        <Button id={`spotterla_carouselarrow_leftto${gtagClickIds[prevButtonId]}`} className="w-10 mx-2 h-10 flex justify-center items-center text-[36px]" ref={prevRef} onClick={goPrev}>
          ←
        </Button>
        <Button id={`spotterla_carouselarrow_rightto${gtagClickIds[nextButtonId]}`} className="w-10 mx-2 h-10 flex justify-center items-center text-[36px]" ref={nextRef} onClick={goNext}>
          →
        </Button>
      </div>
      <div
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="25%"
        data-scroll-repeat="true"
        className={`${Constants.fadeUp} w-full md:px-[10%]`}
      >
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          slidesPerGroup={1}
          loop
          loopFillGroupWithBlank
          className="!overflow-visible w-full"
          onSwiper={(swiperInstance) => { swiper.current = swiperInstance; }}
          navigation={{
            nextEl: nextRef.current ? nextRef.current : undefined,
            prevEl: prevRef.current ? prevRef.current : undefined,
          }}
          onInit={(swiper) => {
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }}
        >
          {data.map((creator, index) => (
            <SwiperSlide key={`${creator.name}-${creator.youtubeUrl}`}>
              <div className={`w-full select-none ${creator.name}`}>
                <FeaturedCreator
                  key={`${creator.name}-${creator.youtubeUrl}`}
                  index={index}
                  id={`${index}`}
                  swiper={swiper}
                  creator={creator}
                  height={height}
                  isMobile={isMobile}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className={`
          lg:max-w-[248px]
          sd:max-w-[250px]
          w-full
          sm:mr-0
          sm:mt-24
          lg:mr-32
          lg:mb-16
          self-end
          text-white
          ${Constants.fadeUp}
        `}
        data-scroll
        data-scroll-class="fade-up-visible"
        data-scroll-offset="20%"
        data-scroll-repeat="true"
      >
        <IconAsterisk
          className="w-14 h-14"
          pathClassName="text-yellow"
        />
        <div
          className={`${Typography.body1} sm:!text-[24px] sm:max-w-[80%] mt-5 mb-8`}
          dangerouslySetInnerHTML={{ __html: content.message }}
        />
        <PrimaryButton
          variant="outlined"
          color="white"
          to="/creators?a=true-stories"
          onClick={() => sendTagEvent('click', {
            event_category: 'Homepage',
            event_label: 'Explore true stories',
          })}
        >
          Explore true stories
        </PrimaryButton>
      </div>
    </div>
  );
};

export default FeaturedCreators;
