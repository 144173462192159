/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from 'react';
import randomFloat from 'random-float';
import randomInt from 'random-int';
import { gsap } from 'gsap';
import { useSelector } from 'react-redux';
import raf from 'raf';
import { SplitText } from 'gsap/SplitText.js';
import useIntersectionObserver from '../../hooks/use-intersection-observer';
import { Typography } from '../../constants/typography';
import { Constants } from '../../constants';
import HomeGetTheGreen from './get-the-green';
import ScrollButton from '../buttons/scroll';
import { selectScrollReady } from '../../state/reducers/utils';

gsap.registerPlugin(SplitText);

const MarqueeWrapper = ({ className, children, parentEl }) => {
  const wrapperEl = useRef();
  const items = useRef();
  const handleRaf = useRef();

  useEffect(() => {
    const w = window.innerWidth;
    const mediaItems = wrapperEl.current.querySelectorAll('.media-item');
    items.current = [...mediaItems].map((item, i) => {
      const width = item.offsetWidth;
      return {
        x: (w * 0.6) + (i * width),
        y: randomInt(-100, 100),
        vx: randomFloat(2, 4),
        width,
        item,
      };
    });
  }, []);

  const render = () => {
    handleRaf.current = raf(render);
    items.current.forEach((item) => {
      item.x -= item.vx;
      const { x, y } = item;
      if (x < -item.width * 2) {
        item.x = window.innerWidth + item.width;
        item.y = randomInt(-100, 180);
        item.vx = randomFloat(2, 4);
      }
      item.item.style.transform = `translate3d(${x}px, ${y}px, 0)`;
    });
  };

  useEffect(() => {
    render();
    return () => {
      raf.cancel(handleRaf.current);
    };
  }, []);

  useIntersectionObserver({
    target: parentEl,
    rootMargin: '0px',
    threshold: 0,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting) {
        raf.cancel(handleRaf.current);
        handleRaf.current = raf(render);
      } else {
        raf.cancel(handleRaf.current);
      }
    },
  });
  return (
    <div ref={wrapperEl} className={`${className}`}>{children}</div>
  );
};

const MediaItem = ({ style, className, data }) => {
  const parentEl = useRef();
  const videoEl = useRef();
  const src = data.video.localFile.publicURL;
  const [isVisible, setIsVisible] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [size, setSize] = useState({ width: style.width, height: style.height });

  useIntersectionObserver({
    target: parentEl,
    rootMargin: '0px',
    threshold: 0,
    onIntersect: ([{ isIntersecting }]) => {
      if (isIntersecting) {
        setIsVisible(true);
        setIsPaused(false);
      } else {
        setIsPaused(true);
      }
    },
  });

  useEffect(() => {
    const vid = videoEl.current;
    if (vid) {
      if (!isPaused) {
        const playPromise = vid.play();
        if (playPromise !== undefined) {
          playPromise.then(() => {
            // Automatic playback started!
          }).catch(() => {
            // Automatic playback failed.
            // Show a UI element to let the user manually start playback.
          });
        }
      } else {
        vid.pause();
      }
    }
  }, [isPaused]);

  useEffect(() => {
    const onResize = () => {
      const scale = Math.min(Math.max(window.innerWidth / 1440, 0.8), 1.6);
      const w = style.width * scale;
      const h = style.height * scale;
      setSize({ width: w, height: h });
    };
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <div style={{ ...size }} ref={parentEl} className={`${className} border border-black overflow-hidden bg-black h-full absolute media-item shadow-2xl`}>
      { isVisible && (
        <video
          ref={videoEl}
          playsInline
          loop
          muted
          autoPlay
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        >
          <source src={src} type="video/mp4" />
        </video>
      )}
    </div>
  );
};

const HomeHero = ({ content, componentA }) => {
  const panels = useRef([]);
  const parentEl = useRef();
  const scrollReady = useSelector(selectScrollReady);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [current, setCurrent] = useState(0);
  const { titles, videoCollage } = content;

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.start();
      window.locoScroll.on('scroll', (e) => {
        if (e.scroll.y > 64) {
          setCurrentIndex(1);
        } else {
          setCurrentIndex(0);
        }
      });
    }
  }, [scrollReady]);

  useEffect(() => {
    if (scrollReady && window.locoScroll) {
      window.locoScroll.on('scroll', (e) => {
        if (e.scroll.y > 64) {
          if (current !== 1) {
            setCurrent(1);
          }
        } else if (current !== 0) {
          setCurrent(0);
        }
      });
    }
  });

  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 0 });
    const heroTitles = parentEl.current.querySelectorAll('.hero-title');
    heroTitles.forEach((item, i) => {
      const splitAll = new SplitText(item, { type: 'words,chars' });
      const baseDelay = 0.6;
      const sectionDelay = 5 * i;
      const delayBetween = 0.4 * i;
      tl.from(splitAll.words, {
        duration: 0.8, y: 100, alpha: 0, stagger: 0.05, ease: 'expo.out',
      }, baseDelay + sectionDelay + delayBetween);
      tl.to(splitAll.words, {
        duration: 0.2, alpha: 0, y: -100, stagger: 0.05, ease: 'circ.in',
      }, baseDelay + 5 + sectionDelay + delayBetween);
    });
  }, []);
  useEffect(() => {
    const mediaWrapper = parentEl.current.querySelectorAll('.media-wrapper')[0];
    gsap.fromTo(
      mediaWrapper,
      { opacity: 0 },
      { opacity: 1, duration: 7, ease: 'circ.inout' },
    );
  }, []);
  const mediaSize = (width, height) => {
    const scale = 1;
    return {
      width: width * scale,
      height: height * scale,
    };
  };
  const mediaItemParams = [
    { size: [430, 288], className: 'mt-20 z-20 rounded-2xl' },
    { size: [398, 255], className: 'ml-10 mt-60 z-30 rounded-xl' },
    { size: [310, 208], className: 'ml-10 mt-40 mr-20 z-20 rounded-xl' },
    { size: [430, 288], className: 'ml-10 mt-0 z-20 rounded-2xl' },
    { size: [243, 155], className: 'ml-20 mt-48 z-30 rounded-xl' },
    { size: [676, 462], className: '-ml-20 mt-20 z-0 rounded-3xl' },
    { size: [310, 208], className: 'ml-10 mt-40 mr-20 z-20 rounded-xl' },
  ];
  return (
    <div
      id="home-hero"
    >
      <div>
        <div
          ref={(el) => panels.current.push(el)}
          data-scroll
          data-scroll-class="fade-up-visible"
          data-scroll-offset="25%"
          data-scroll-repeat="true"
          className={`${Constants.fadeUp} relative z-10`}
        >
          <div
            ref={parentEl}
            className={`
              ${Constants.panelBase}
              sm:responsive-h-screen
              tp:responsive-h-screen
              tl:responsive-h-screen
              bg-charcoal
              light-header header-section
              flex items-end justify-start
              w-screen overflow-hidden
            `}
          >
            <div className="relative w-full h-full">
              <div className="absolute bottom-0 w-full h-full z-10 px-8 pb-20 lg:p-16">
                {
                  titles.map((item) => (
                    <div key={`home-hero-title-${item.titleOutline}-${item.titleFill}`} className={`${Typography.h1} text-white absolute bottom-0 hero-title pb-20 lg:pb-16 sm:max-w-[80%]`}>
                      <div className={`block ${Typography.strokedWhite} title-outline mb-2`} dangerouslySetInnerHTML={{ __html: item.titleOutline }} />
                      <div className="block title-fill" dangerouslySetInnerHTML={{ __html: item.titleFill }} />
                    </div>
                  ))
                }
              </div>
              <div className="w-full h-full">
                <div style={{ opacity: 0 }} className="absolute bottom-0 w-full lg:w-[180%] z-0 top-[40%] transform -translate-y-1/2 media-wrapper">
                  <div className="w-full">
                    <MarqueeWrapper className="w-full flex" parentEl={parentEl}>
                      {
                        videoCollage.map((item, i) => {
                          const { size, className } = mediaItemParams[i % mediaItemParams.length];
                          return (
                            <MediaItem
                              key={`media-item-${size[0]}-${size[1]}-${i}`}
                              style={{ ...mediaSize(size[0], size[1]) }}
                              data={item}
                              className={className}
                            />
                          );
                        })
                      }
                    </MarqueeWrapper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative z-10" ref={(el) => panels.current.push(el)}>
          <HomeGetTheGreen
            content={{
              fillText: componentA.titleFill,
              outlineText: componentA.titleOutline,
              bodyText: componentA.paragraph,
              learnMoreButton: {
                label: componentA.buttonA.label,
                link: componentA.buttonA.link,
              },
              partnerButton: {
                label: componentA.buttonB.label,
                link: componentA.buttonB.link,
              },
            }}
          />
        </div>
      </div>
      <div
        className={`${Constants.stickyScrollButton} fixed`}
        data-scroll
        data-scroll-sticky
        data-scroll-target="#home-hero"
      >
        <ScrollButton
          location="down"
          arrowNo={current + 1}
          positionClassName="relative"
          colorClassName={`transition-all duration-500 ${currentIndex === 0 ? 'text-white' : 'text-charcoal'}`}
          scrollTo={currentIndex === 0 ? 'get-the-green' : 'fast-track'}
        />
      </div>
    </div>
  );
};

export default HomeHero;
